export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/purchase-order/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;

        if (filterUrl) url += filterUrl;
        if (page) url += `page=${page}`
        return url;
    },
    get: (id) => `/api/purchase-order/${id}/detail`,
    save: `/api/purchase-order/create`,
    delete: (id) => `/api/purchase-order/${id}/delete`,
    bulkDelete: (id) => `/api/purchase-order/delete`,

    update: `/api/purchase-order/update`,
};

export const STRUCTURE = [
    {
        label: "Client Name",
        filterLabel: "Client name",
        filterName: "client_id",
        type: "text",
    }

];

