import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/DashboardScreen";

//

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesMaintenance from "../pages/Utility/pages-maintenance";

// Authentication related pages
import Loginnew from "../pages/Authentication/Auth";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Supervisors from "../pages/Supervisor/Supervisor";

//  // Inner Authentication
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import Login1 from "../pages/AuthenticationInner/Login";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Register1 from "../pages/AuthenticationInner/Register";

// Profile
// import UserProfile from "../pages/Authentication/user-profile"
import Profile from "../pages/Profile/Profile";
// import UserProfile from "../pages/Authentication/user-profile";

//Demo
import Campus from "../pages/Campus/Campus";
import PaymentLogs from "../pages/PaymentLogs/PaymentLogs";

// import EditCampus from "../pages/Campus/EditCampus"
import Feedback from "../pages/Feedbacks/Feedback";
import Filter from "../pages/Filter/EditZones";

import NameMaster from "../components/Dropdowns/NameMasters/NameMaster";
import EditSuperVisorRole from "../pages/SupervisorRoles/EditSuperVisorRoles";
import SuperVisorRole from "../pages/SupervisorRoles/SuperVisorRoles";
import Zones from "../pages/Zone/Zone";

import Corporates from "../pages/Corporates/Corporates";
//Fuel
import Fuel from "../pages/Fuel/Fuel";
import FuelPrice from "../pages/FuelPrice/FuelPrice";
import Roles from "../pages/Roles/Role";


//Itinerary
import Itineraries from "../pages/Itinerary/Itinerary";


//feedback

//sos
import SOSLogs from "../pages/SOSLogs/SOSLogs";
//offer
import Comments from "../components/Comments/Comments";
import Offer from "../pages/Offer/Offer";

// import EditOffer from '../pages/Offer/EditOffer'
import AccessLogs from "../pages/AccessLog/AccessLog";
import Addons from "../pages/Addon/Addon";
import ApiLog from "../pages/ApiLog/ApiLog";
import Bids from "../pages/Bid/Bid";
import Booking from "../pages/Booking/Booking";
import BookingNew from "../pages/BookingsNew/Booking";
import City from "../pages/City/City";
import ClientPools from "../pages/ClientPool/ClientPool";
import Clients from "../pages/Clients/Client";
import Company from "../pages/Company/Company";
import Country from "../pages/Countries/Country";
import CreditNote from "../pages/CreditNotes/CreditNote";
import CustomFields from "../pages/CustomFields/CustomField";
import SettingUp from "../pages/Demo/SettingUp";
import testingPage from "../pages/Demo/testingPage";
import DocumentCategories from "../pages/DocumentCategory/DocumentCategory";
import Driver from "../pages/Driver/Driver";
import Expenses from "../pages/Expense/Expense";
import Flights from "../pages/Flight/Flight";
import Garages from "../pages/Garage/Garage";
import HolidayConf from "../pages/HolidayConfiguration/HolidayConf";
import Incidents from "../pages/Incident/Incident";
import Invoice from "../pages/Invoice/Invoice";
import InvoiceConfig from "../pages/InvoiceConfig/InvoiceConfig";
import Language from "../pages/Language/Language";
import MessageTemplates from "../pages/MessageTemplate/MessageTemplate";
import MyAccount from "../pages/MyAccount/MyAccount";
import PaymentGateway from "../pages/Payment_Gateway/PaymentGateway";
import Payment_Gateway from "../pages/PaymentGatewayAccunt/Payment_Gateway";
import Qc from "../pages/qc/qc";
import RateAreas from "../pages/RateArea/RateArea";
import RPFs from "../pages/RFP/RFP";
import Settings from "../pages/Settings/Settings";
import State from "../pages/State/State";
import Stays from "../pages/Stay/Stay";
import Suppliers from "../pages/Suppliers/Supplier";
import Tax from "../pages/Tax/Tax";

import StaticContent from "../pages/StaticContent/StaticContent";
import Subscription from "../pages/Subcriptions/Subscription";
import TaxSlab from "../pages/TaxSlab/TaxSlab";
import Vehicle from "../pages/Vehicle/Vehicle";

import Trip_Routes from "../pages/RecurringTrip/TripRoute";

import Bills from "../pages/Bills/Bills";
import TransferGroup from "../pages/configs/TransferGroup/TransferGroup";
import ConvertCurrency from "../pages/ConvertCurrency/ConvertCurrency";
import Leave from "../pages/Leave/Leave";
import PaymentMade from "../pages/PaymentMade/PaymentMade";
import PaymentReceived from "../pages/PaymentReceived/PaymentReceived";
import Rate from "../pages/Rate/Rate";
import RateNames from "../pages/RateName/RateName";
import Timesheet from "../pages/Timesheet/Timesheet";
import User from "../pages/Users/User";

import Helpline from "../pages/Configurations/Tabs/Helpline/Helpline";
import SysConfig from "../pages/Configurations/Tabs/SystemConfig/SysConfig";
import CorpSubs from "../pages/CorpSubs/CorpSubs";

import CityGroups from "../pages/CityGroups/CityGroups";
import ClientGroups from "../pages/ClientGroups/ClientGroups";
import Compilances from "../pages/Compilances/Compilances";
import Configurations from "../pages/Configurations/Configurations";
import JourneyGap from "../pages/Configurations/Tabs/JourneyGap/JourneyGap";
import Languages from "../pages/Configurations/Tabs/Languages/Languages";
import OtherSettings from "../pages/Configurations/Tabs/OtherSettings/OtherSettings";
import RouteSettings from "../pages/Configurations/Tabs/RouteSettings/RouteSettings";
import RoutingSettings from "../pages/Configurations/Tabs/RoutingSettings/RoutingSettings";
import TariffSettings from "../pages/Configurations/Tabs/TariffSettings/TariffSettings";
// import Demo2 from "../pages/Demo/Demo2";
import EditFuelPrice from "../pages/FuelPrice/EditFuelPrice";
import InvoiceSetup from "../pages/InvoiceSetup/InvoiceSetup";
import Template from "../pages/Template/Template";
import Tickets from "../pages/Tickets/Tickets";
import Translations from "../pages/Translations/Translations";
import WorkShift from "../pages/WorkShift/WorkShift";

// import AdminHome from '../pages/Admin/Home'
import AdminHelpRoom from "../pages/Admin/components/HelpRoom";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import Successs from "../pages/AdminDashboard/Successs";
import Offices from "../pages/Office/Office";
import UserMaster from "../pages/UserMaster/UserMaster";
import VehicleType from "../pages/VehicleType/VehicleType";

//terms
import Terms from "../pages/Terms/Term";

// reports
import Request from "../pages/AdhocRequest/AdhocRequest";
import FundAccount from "../pages/FundAccount/FundAccount";
import Agent from "../pages/MessageAgent/MessageAgent";
import TripReport from "../pages/Reports/TripArrivalReport/Report";
import SummaryReport from "../pages/Reports/TripSummary/Report";
import Transaction from "../pages/Transaction/Transaction";

import Branch from "../pages/Branch/Branch";
import MarketPlace from "../pages/MarketPlace/MarketPlace";
import SupervisorRole from "../pages/SupervisorRole/SupervisorRole";

import Accounts from "../pages/Accounts/Accounts";
import Auth from "../pages/Authentication/Auth";
import ContactPersons from "../pages/ContactPerson/ContactPerson";
import CustomField from "../pages/CustomField/CustomField";
import DemoMap from "../pages/Demo/DemoMap";
import DemoV2 from "../pages/Demo/DemoV2";
import Employees from "../pages/Employee/Employee";
import MakeModel from "../pages/MakeModel/MakeModel";
import BidsDashboard from "../pages/MarketPlace/BidsDashboard";
import MarketPlaceDashboard from "../pages/MarketPlace/MarketPlaceDashboard";
import MyRfpDashboard from "../pages/MarketPlace/MyRfpDashboard";
import QueriesDashboard from "../pages/MarketPlace/QueriesDashboard";
import RfpDetails from "../pages/MarketPlace/RfpDetails";
import RfpSubmitRate from "../pages/MarketPlace/RfpSubmitRate";
import SupplierDashboard from "../pages/MarketPlace/SupplierDashboard";
import NewFuel from "../pages/NewFuel/NewFuel";
import Permits from "../pages/Permit/Permit";
// import Tag from "../pages/Tag/Tag";
import TestV2 from "../pages/Utility/test-V2";

//corporate
import Corporate from "../pages/Corporate/Corporate";

//super admin
import Lead from "../components/dnd/listView/Lead";
import SignupProfileUpdate from "../pages/Authentication/SignupSteps/SignupProfileUpdate";
import Banner from "../pages/Banner/Banner";
import CalenderPage from "../pages/Calender/CalenderPage";
import CRMLayout from "../pages/CRM/CRMLayout";
import DemoV3 from "../pages/Demo/DemoV3";
import EmailLogs from "../pages/Email Logs/EmailLogs";
import GroupTour from "../pages/GroupTour/GroupTour";
import Inbox from "../pages/Inbox/Inbox";
import LoginIn from "../pages/Login/LoginIn";
import MapLogs from "../pages/Map Logs/MapLogs";
import SMSLogs from "../pages/Sms Logs/SMSLogs";
import StatusMaster from "../pages/StatusMaster/StatusMaster";
import SuperAdmin from "../pages/Super Admin/SuperAdmin";
import SwitchProfile from "../pages/SwitchProfile/SwitchProfile";
import TripJourneyGap from "../pages/TripJourneyGap/TripJourneyGap";
import Payment from "../pages/Utility/Payment";
import PaymentStatus from "../pages/Utility/PaymentStatus";
import PayNow from "../pages/Utility/PayNow";
import Rostering from "../pages/Rostering";
// import Booking from "../pages/BookingsNew/Booking";
// import Employee

// import LoginNext from "../pages/Authentication/LoginNext";
// import SignUp from "../pages/Authentication/SignUp";
import UserApps from "../pages/Utility/UserApps";
import WhatsAppLogs from "../pages/WhatsApp Logs/WhatsAppLogs";
import DemoV4 from "../pages/Demo/DemoV4";
import Hotel from "../pages/Hotel/Hotel";
import Purchase from "../pages/PurchaseOrder/Purchase";

const userRoutes = [
  { path: "/admin", Component: AdminHelpRoom },
  { path: "/dashboard", Component: Dashboard },

  { path: "/compilances", Component: Compilances },
  { path: "/feedback", Component: Feedback },

  { path: "/purchase-order", Component: Purchase },
  { path: "/purchase-order/edit/:edit_id", Component: Purchase },
  { path: "/purchase-order/create/", Component: Purchase },

  { path: "/newfuel", Component: NewFuel },
  { path: "/newfuel/edit/:edit_id", Component: NewFuel },
  { path: "/newfuel/create/", Component: NewFuel },

  { path: "/booking", Component: BookingNew },
  { path: "/booking/edit/:edit_id", Component: BookingNew },
  { path: "/booking/create/", Component: BookingNew },

  { path: "/trips", Component: BookingNew },
  { path: "/trips/edit/:edit_id", Component: BookingNew },
  { path: "/trips/create/", Component: BookingNew },

  { path: "/vehicle", Component: Vehicle },
  { path: "/vehicle/edit/:vehicle_id", Component: Vehicle },
  { path: "/vehicle/create/", Component: Vehicle },

  { path: "/customfield", Component: CustomField },
  { path: "/customfield/edit/:edit_id", Component: CustomField },
  { path: "/customfield/create/", Component: CustomField },

  { path: "/vehicle-type", Component: VehicleType },
  { path: "/vehicle-type/edit/:edit_id", Component: VehicleType },
  { path: "/vehicle-type/create/", Component: VehicleType },

  { path: "/make-model", Component: MakeModel },
  { path: "/make-model/edit/:edit_id", Component: MakeModel },
  { path: "/make-model/create/", Component: MakeModel },

  { path: "/translations", Component: Translations },
  { path: "/translations/edit/:edit_id", Component: Translations },
  { path: "/translations/create/", Component: Translations },

  { path: "/country", Component: Country },
  { path: "/country/edit/:edit_id", Component: Country },
  { path: "/country/create/", Component: Country },

  { path: "/comments", Component: Comments },

  { path: "/namemaster", Component: NameMaster },
  { path: "/namemaster/edit/:name_id", Component: NameMaster },
  { path: "/namemaster/create/", Component: NameMaster },

  { path: "/timesheet", Component: Timesheet },
  { path: "/timesheet/edit/:edit_id", Component: Timesheet },
  { path: "/timesheet/create/", Component: Timesheet },

  { path: "/accounts", Component: Accounts },
  { path: "/accounts/edit/:edit_id", Component: Accounts },
  { path: "/accounts/create/", Component: Accounts },

  // { path: "/tag-element", Component: Tag },
  // { path: "/tag-element/edit/:edit_id", Component: Tag },
  // { path: "/tag-element/create/", Component: Tag },

  { path: "/employee", Component: Employees },
  { path: "/employee/edit/:employee_id", Component: Employees },
  { path: "/employee/create/", Component: Employees },

  { path: "/status-master", Component: StatusMaster },
  { path: "/status-master/edit/:status", Component: StatusMaster },
  { path: "/status-master/create/", Component: StatusMaster },


  { path: "/permit", Component: Permits },
  { path: "/permit/edit/:permit_id", Component: Permits },
  { path: "/permit/create/", Component: Permits },

  { path: "/office", Component: Offices },
  { path: "/office/edit/:office_id", Component: Offices },
  { path: "/office/create/", Component: Offices },

  { path: "/client-pool", Component: ClientPools },
  { path: "/client-pool/edit/:edit_id", Component: ClientPools },
  { path: "/client-pool/create/", Component: ClientPools },

  { path: "/messagetemplate", Component: MessageTemplates },
  { path: "/messagetemplate/edit/:edit_id", Component: MessageTemplates },
  { path: "/messagetemplate/create/", Component: MessageTemplates },

  //corporate subscriptions
  { path: "/subscription", Component: Subscription },
  { path: "/subscription/edit/:edit_id", Component: Subscription },
  { path: "/subscription/create/", Component: Subscription },

  //access logs
  { path: "/accesslog", Component: AccessLogs },

  //api logs
  { path: "/apiLogs", Component: ApiLog },
  { path: "/apiLogs/detail/:id", Component: ApiLog },

  //credit note
  { path: "/creditNote", Component: CreditNote },
  { path: "/creditNote/edit/:edit_id", Component: CreditNote },
  { path: "/creditNote/create", Component: CreditNote },

  { path: "/campus", Component: Campus },
  { path: "/campus/edit/:edit_id", Component: Campus },
  { path: "/campus/create/", Component: Campus },
  // { path: "/campus/new", Component: NewCampus },

  { path: "/leave", Component: Leave },
  { path: "/leave/edit/:edit_id", Component: Leave },
  { path: "/leave/create/", Component: Leave },

  { path: "/driver", Component: Driver },
  { path: "/driver/edit/:edit_id", Component: Driver },
  { path: "/driver/create/", Component: Driver },

  { path: "/agent", Component: Agent },
  { path: "/agent/edit/:edit_id", Component: Agent },
  { path: "/agent/create/", Component: Agent },

  { path: "/incident", Component: Incidents },
  { path: "/incident/edit/:edit_id", Component: Incidents },
  { path: "/incident/create/", Component: Incidents },

  { path: "/doc-category", Component: DocumentCategories },
  { path: "/doc-category/edit/:edit_id", Component: DocumentCategories },
  { path: "/doc-category/create/", Component: DocumentCategories },

  { path: "/itinerary", Component: Itineraries },
  { path: "/itinerary/edit/:edit_id", Component: Itineraries },
  { path: "/itinerary/create/", Component: Itineraries },

  { path: "/tour", Component: GroupTour },
  { path: "/tour/edit/:grpTour_id", Component: GroupTour },
  { path: "/tour/create/", Component: GroupTour },

  { path: "/request", Component: Request },
  { path: "/request/edit/:edit_id", Component: Request },
  { path: "/request/create/", Component: Request },

  { path: "/payment-logs", Component: PaymentLogs },
  { path: "/payment-logs/new", Component: PaymentLogs },
  { path: "/payment-logs/edit/:edit_id", Component: PaymentLogs },
  { path: "/payment-logs/create/", Component: PaymentLogs },

  { path: "/rfp", Component: RPFs },
  { path: "/rfp/edit/:edit_id", Component: RPFs },
  { path: "/rfp/create/", Component: RPFs },

  { path: "/bid", Component: Bids },
  { path: "/bid/edit/:edit_id", Component: Bids },
  { path: "/bid/create/", Component: Bids },

  { path: "/garage", Component: Garages },
  { path: "/garage/edit/:edit_id", Component: Garages },
  { path: "/garage/create/", Component: Garages },

  { path: "/flight", Component: Flights },
  { path: "/flight/edit/:edit_id", Component: Flights },
  { path: "/flight/create/", Component: Flights },

  { path: "/tours/calender", Component: CalenderPage },
  { path: "/tours/calender/:edit_id", Component: CalenderPage },
  { path: "/tours/calender/", Component: CalenderPage },

  { path: "/stay", Component: Stays },
  { path: "/stay/edit/:edit_id", Component: Stays },
  { path: "/stay/create/", Component: Stays },

  { path: "/leads", Component: Lead },
  { path: "/leads/edit/:edit_id", Component: Lead },
  { path: "/leads/create/", Component: Lead },

  { path: "/custom-field", Component: CustomFields },
  { path: "/custom-field/edit/:edit_id", Component: CustomFields },
  { path: "/custom-field/create/", Component: CustomFields },

  { path: "/payment-gateway", Component: PaymentGateway },
  { path: "/payment-gateway/edit/:edit_id", Component: PaymentGateway },
  { path: "/payment-gateway/create/", Component: PaymentGateway },

  { path: "/payment-gateway-account", Component: Payment_Gateway },
  {
    path: "/payment-gateway-account/edit/:edit_id",
    Component: Payment_Gateway,
  },
  { path: "/payment-gateway-account/create/", Component: Payment_Gateway },

  { path: "/addon", Component: Addons },
  { path: "/addon/edit/:edit_id", Component: Addons },
  { path: "/addon/create/", Component: Addons },

  { path: "/supervisor", Component: Supervisors },
  { path: "/supervisor/edit/:supervisor_id", Component: Supervisors },
  { path: "/supervisor/create/", Component: Supervisors },

  { path: "/supplier", Component: Suppliers },
  { path: "/supplier/edit/:edit_id", Component: Suppliers },
  { path: "/supplier/create/", Component: Suppliers },

  { path: "/company", Component: Company },
  { path: "/company/edit/:company_id", Component: Company },
  { path: "/company/create/", Component: Company },

  { path: "/rate-area", Component: RateAreas },
  { path: "/rate-area/edit/:edit_id", Component: RateAreas },
  { path: "/rate-area/create/", Component: RateAreas },

  { path: "/tripJourneyGap", Component: TripJourneyGap },
  { path: "/tripJourneyGap/edit/:edit_id", Component: TripJourneyGap },
  { path: "/tripJourneyGap/create/", Component: TripJourneyGap },


  { path: "/rateName", Component: RateNames },
  { path: "/rateName/edit/:edit_id", Component: RateNames },
  { path: "/rateName/create/", Component: RateNames },

  { path: "/term", Component: Terms },
  { path: "/term/edit/:edit_id", Component: Terms },
  { path: "/term/create/", Component: Terms },

  { path: "/expense", Component: Expenses },
  { path: "/expense/edit/:expense_id", Component: Expenses },
  { path: "/expense/create/", Component: Expenses },

  { path: "/contact-person", Component: ContactPersons },
  { path: "/contact-person/edit/:edit_id", Component: ContactPersons },
  { path: "/contact-person/create/", Component: ContactPersons },
  { path: "/testing-page", Component: testingPage },

  { path: "/tariffsettings", Component: TariffSettings },

  { path: "/zones", Component: Zones },
  { path: "/zones/edit/:edit_id", Component: Zones },
  { path: "/zones/create/", Component: Zones },

  //super admin
  { path: "/superAdmin", Component: SuperAdmin },
  { path: "/superAdmin/edit/:edit_id", Component: SuperAdmin },
  { path: "/superAdmin/create", Component: SuperAdmin },

  //corporate
  { path: "/corporate", Component: Corporate },
  { path: "/corporate/edit/:edit_id", Component: Corporate },
  // { path: "/corporate/create", Component: Corporate },

  //clients
  { path: "/client", Component: Clients },
  { path: "/client/edit/:client_id", Component: Clients },
  { path: "/client/create/", Component: Clients },

  { path: "/role", Component: Roles },
  { path: "/role/edit/:role_id", Component: Roles },
  { path: "/role/create/", Component: Roles },

  { path: "/corporate", Component: Corporates },
  { path: "/corporate/edit/:edit_id", Component: Corporates },
  //{ path: "/corporate/create/", Component: Corporates },
  { path: "/corporate/create/", Component: Corporates },

  { path: "/state", Component: State },
  { path: "/state/edit/:edit_id", Component: State },
  { path: "/state/create/", Component: State },

  { path: "/qc", Component: Qc },
  { path: "/qc/edit/:edit_id", Component: Qc },
  { path: "/qc/create/", Component: Qc },

  { path: "/city", Component: City },
  { path: "/city/edit/:edit_id", Component: City },
  { path: "/city/create/", Component: City },

  { path: "/language", Component: Language },
  { path: "/language/edit/:edit_id", Component: Language },
  { path: "/language/create/", Component: Language },

  { path: "/invoice-config", Component: InvoiceConfig },
  { path: "/invoice-config/:edit_id", Component: InvoiceConfig },
  { path: "/invoice-config/create", Component: InvoiceConfig },

  // { path: "/invoice-config", Component: InvoiceConfigs },
  // { path: "/invoice-config/edit/:edit_id", Component: InvoiceConfigs },
  // { path: "/invoice-config/create/", Component: InvoiceConfigs },

  { path: "/tax", Component: Tax },
  { path: "/tax/edit/:edit_id", Component: Tax },
  { path: "/tax/create/", Component: Tax },

  { path: "/staticcontent", Component: StaticContent },
  { path: "/staticcontent/edit/:edit_id", Component: StaticContent },
  { path: "/staticcontent/create/", Component: StaticContent },

  { path: "/languages", Component: Languages },

  { path: "/journeygap", Component: JourneyGap },

  { path: "/helpline", Component: Helpline },
  { path: "/sysConfig", Component: SysConfig },
  { path: "/corpSubscription", Component: CorpSubs },
  { path: "/corpSubscription/create/:edit_id", Component: CorpSubs },
  { path: "/corpSubscription/create", Component: CorpSubs },

  { path: "/routesettings", Component: RouteSettings },

  { path: "/citygroups", Component: CityGroups },
  { path: "/citygroups/edit/:edit_id", Component: CityGroups },
  { path: "/citygroups/create/", Component: CityGroups },

  { path: "/clientgroups", Component: ClientGroups },
  { path: "/clientgroups/edit/:edit_id", Component: ClientGroups },
  { path: "/clientgroups/create/", Component: ClientGroups },

  { path: "/tickets", Component: Tickets },
  { path: "/tickets/edit/:ticket_id", Component: Tickets },
  { path: "/tickets/create/", Component: Tickets },

  { path: "/holidayconf", Component: HolidayConf },
  { path: "/holidayconf/edit/:edit_id", Component: HolidayConf },
  { path: "/holidayconf/create/", Component: HolidayConf },

  { path: "/templates", Component: Template },
  { path: "/templates/edit/:edit_id", Component: Template },
  { path: "/templates/edit/:edit_id/:isClone", Component: Template },
  { path: "/templates/create/", Component: Template },

  // { path: "/templates", Component: Templates },
  // { path: "/templates/edit/:id", Component: Templates },
  // { path: "/templates/edit/", Component: Templates },
  // { path: "/templates/edit/:id/:isClone", Component: Templates },
  // { path: "/templates/create/", Component: Templates },

  { path: "/workshift", Component: WorkShift },
  { path: "/workshift/edit/:edit_id", Component: WorkShift },
  { path: "/workshift/create/", Component: WorkShift },

  { path: "/settings", Component: Settings },

  { path: "/routingsettings", Component: RoutingSettings },
  { path: "/othersettings", Component: OtherSettings },

  { path: "/supervisorrole/", Component: SupervisorRole },
  { path: "/supervisorrole/edit/:edit_id", Component: SupervisorRole },
  { path: "/supervisorrole/create", Component: SupervisorRole },

  { path: "/supervisor-role/", Component: SuperVisorRole },
  { path: "/supervisor-role/edit/:edit_id", Component: EditSuperVisorRole },
  { path: "/supervisor-role/create", Component: EditSuperVisorRole },
  // { path: "/demo2", Component: Demo2 },

  // { path: "/demo-old", Component: Demo },
  { path: "/demo", Component: DemoV2 },
  { path: "/demo-drag", Component: DemoV4 },
  { path: "/demo-booking", Component: DemoV3 },
  { path: "/demo-map", Component: DemoMap },

  { path: "/admindashboard", Component: AdminDashboard },
  { path: "/success", Component: Successs },

  { path: "/taxslab", Component: TaxSlab },
  { path: "/taxslab/create", Component: TaxSlab },
  { path: "/taxslab/edit/:edit_id", Component: TaxSlab },

  { path: "/rate", Component: Rate },
  // { path: "/rate/edit/:edit_id", Component: Rate },
  { path: "/rate/edit/:rateNo", Component: Rate },
  { path: "/rate/create/", Component: Rate },

  { path: "/fuel/", Component: Fuel },
  { path: "/fuel/create", Component: Fuel },
  { path: "/fuel/edit/:edit_id", Component: Fuel },

  { path: "/fundaccounts", Component: FundAccount },
  { path: "/fundaccounts/create", Component: FundAccount },
  { path: "/fundaccounts/edit/:edit_id", Component: FundAccount },

  { path: "/transaction", Component: Transaction },
  { path: "/transaction/create", Component: Transaction },
  // { path: "/transaction/edit/:edit_id", Component: Transaction },

  // { path: "/accountTransactions/", Component: AccountTransaction },
  // { path: "/accountTransactions/create", Component: AccountTransaction },
  // { path: "/accountTransactions/edit/:edit_id", Component: AccountTransaction },

  { path: "/offer", Component: Offer },
  { path: "/offer/create/", Component: Offer },
  { path: "/offer/edit/:edit_id", Component: Offer },

  { path: "/sos", Component: SOSLogs },

  { path: "/transfergroup", Component: TransferGroup },
  { path: "/transfergroup/create/", Component: TransferGroup },
  { path: "/transfergroup/edit/:edit_id", Component: TransferGroup },

  { path: "/invoice", Component: Invoice },
  { path: "/invoice/create/", Component: Invoice },
  { path: "/invoice/edit/:edit_id", Component: Invoice },

  { path: "/banner", Component: Banner },
  { path: "/banner/create/", Component: Banner },
  { path: "/banner/edit/:edit_id", Component: Banner },

  { path: "/invoiceconfig", Component: InvoiceConfig },
  { path: "/invoiceconfig/create/", Component: InvoiceConfig },
  { path: "/invoiceconfig/edit/:edit_id", Component: InvoiceConfig },
  { path: "/coming-soon", Component: TestV2 },
  { path: "/route", Component: Trip_Routes },

  { path: "/route/create/", Component: Trip_Routes },
  { path: "/route/edit/:edit_id", Component: Trip_Routes },

  { path: "/payment-made", Component: PaymentMade },
  { path: "/payment-made/edit/:edit_id", Component: PaymentMade },
  { path: "/payment-made/create/", Component: PaymentMade },

  { path: "/payment-received", Component: PaymentReceived },
  { path: "/payment-received/create", Component: PaymentReceived },
  { path: "/payment-received/edit/:edit_id", Component: PaymentReceived },

  { path: "/configurations", Component: Configurations },

  { path: "/smslogs", Component: SMSLogs },
  { path: "/whatsAppLogs", Component: WhatsAppLogs },
  { path: "/emailLogs", Component: EmailLogs },
  { path: "/mapLogs", Component: MapLogs },

  { path: "/convertcurrency", Component: ConvertCurrency },
  { path: "/convertcurrency/edit/:edit_id", Component: ConvertCurrency },
  { path: "/convertcurrency/create/", Component: ConvertCurrency },

  { path: "/invoicesetup", Component: InvoiceSetup },
  { path: "/invoicesetup/edit/:edit_id", Component: InvoiceSetup },
  { path: "/invoicesetup/create/", Component: InvoiceSetup },

  { path: "/fuel-price", Component: FuelPrice },
  { path: "/fuel-price/edit/:edit_id", Component: EditFuelPrice },
  { path: "/fuel-price/create", Component: EditFuelPrice },

  { path: "/setting-up", Component: SettingUp },
  // { path: "/account", Component: Account },
  { path: "/myaccount", Component: MyAccount },
  { path: "/filter", Component: Filter },
  { path: "/profile", Component: Profile },

  { path: "/user", Component: User },
  { path: "/user/create", Component: User },
  { path: "/user/edit/:user_id", Component: User },

  { path: "/request", Component: Request },
  { path: "/request/create", Component: Request },
  { path: "/request/edit/:edit_id", Component: Request },

  { path: "/guests", Component: User },
  { path: "/guests/create", Component: User },
  { path: "/guests/edit/:user_id", Component: User },

  { path: "/userMaster", Component: UserMaster },
  { path: "/userMaster/edit/:edit_id", Component: UserMaster },
  { path: "/userMaster/create", Component: UserMaster },

  { path: "/branch", Component: Branch },
  { path: "/branch/edit/:edit_id", Component: Branch },
  { path: "/branch/create/", Component: Branch },

  { path: "/hotel", Component: Hotel },
  { path: "/hotel/edit/:edit_id", Component: Hotel },
  { path: "/hotel/create", Component: Hotel },

  { path: "/bill", Component: Bills },
  { path: "/bill/create", Component: Bills },
  { path: "/bill/edit/:bill_id", Component: Bills },
  // { path: "/expenses", Component: Expenses },
  // { path: "/expenses/create", Component: Expenses },
  // { path: "/expenses/edit/:id", Component: Expenses },

  // reports
  { path: "/tripReport", Component: TripReport },
  { path: "/summaryReport", Component: SummaryReport },

  { path: "/marketplace", Component: MarketPlace },
  // { path: "/create/client", Component: CreateClient },
  // { path: "/create/transporter", Component: CreateTransporter },
  { path: "/marketplace/dashboard", Component: MarketPlaceDashboard },
  { path: "/marketplace/myrfp/dashboard", Component: MyRfpDashboard },
  { path: "/marketplace/supplier/dashboard", Component: SupplierDashboard },
  { path: "/marketplace/queries/dashboard", Component: QueriesDashboard },
  { path: "/marketplace/bids/dashboard", Component: BidsDashboard },
  { path: "/marketplace/rfp/details/:id", Component: RfpDetails },
  { path: "/marketplace/rfp/submit", Component: RfpSubmitRate },

  { path: "/inbox", Component: Inbox },
  { path: "/rostering", Component: Rostering },

  { path: "/crm/email", Component: CRMLayout },
  { path: "/crm/email/:email_id", Component: CRMLayout },
  { path: "/crm/whatsapp", Component: CRMLayout },
  { path: "/crm/whatsapp/:message_id", Component: CRMLayout },
  { path: "/crm/sms", Component: CRMLayout },
  { path: "/crm/sms/:sms_id", Component: CRMLayout },

  { path: "/crm/inbox", Component: CRMLayout },
  { path: "/crm/inbox/:inbox_id", Component: CRMLayout },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        // return <Navigate to="/dashboard" />;
        return <Navigate to="/dashboard" />;
      }
      return <Navigate to="/" />;
    },
  },
];

const authRoutes = [
  // { path : "/" , Component : Login},
  { path: "/logout", Component: Logout },

  {
    path: "/",
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        return <Navigate to="/dashboard" />;
      }
      return <Loginnew />;
    },
  },
  {
    path: "/cpanel",
    Component: () => {
      return <Loginnew isCpanelLogin />;
    },
  },

  { path: "/forgot-password", Component: ForgetPwd },
  { path: "/register", Component: Register },
  { path: "/choose-profile", Component: SwitchProfile },
  { path: "/login", Component: LoginIn },
  {
    path: "/sign-up",
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        return <Navigate to="/dashboard" />;
      }
      return <Auth />;
    },
  },
  { path: "/signup-profile", Component: SignupProfileUpdate },

  { path: "/pages-maintenance", Component: PagesMaintenance },
  { path: "/pages-comingsoon", Component: PagesComingsoon },
  { path: "/pages-404", Component: Pages404 },
  { path: "/pay-now/:request_id", Component: PayNow },
  { path: "/pay/now", Component: Payment },
  { path: "/payment/txn/status", Component: PaymentStatus },
  { path: "/user-apps", Component: UserApps },
  { path: "/pages-500", Component: Pages500 },

  { path: "/pages-login", Component: Login1 },
  { path: "/pages-register", Component: Register1 },
  { path: "/page-recoverpw", Component: Recoverpw },
  { path: "/auth-lock-screen", Component: LockScreen },
];

export { authRoutes, userRoutes };
