import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./PurchaseConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    taxCache = []
    version = 0;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        if (!this?.rowsPerPage) {
            const rows = await getUiPrefRows("tax.grid")
            this.rowsPerPage = rows ?? 40
        }

        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
    };

    fetchAll = async function (props) {
        if (this.taxCache.length) {
            return this.taxCache
        }
        try {
            const response = await doGET(ENDPOINTS.grid(0, -1));
            if (response.status === 200) {
                this.taxCache = response?.data?.rows?.map((v) => {
                    return { ...v, label: v.name, value: v._id };
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    edit = async function (data, id) {
        const response = await doPUT(ENDPOINTS?.update, data);
        if (response?.status === 200) {
            this.version += 1;
            this.taxCache = []
            this.fetchAll()
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, data);
        if (response.status === 200) {
            this.taxCache = []
            this.fetch()
            this.fetchAll()
        }
        return response?.data?._id;

    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch();
            this.taxCache = []

        }
    };
    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();

        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };

}

const purchaseService = new Service();
export default purchaseService;

