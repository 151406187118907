import moment from "moment";
import { FEMALE_COLOR, MALE_COLOR } from "../components/Rostering/utils";
import CustomerLabelService from "../components/Dropdowns/CustomerSelect/CustomerLabelService";
import { monthNames } from "../common/utils/Constants";
import toastr from "toastr";
import UIPrefService from "../components/DataGrid/UIPrefService";
import { doPUT } from "./HttpUtil";

export function isArrayNonEmpty(param) {
    return Array.isArray(param) && param.length > 0;
}
export function arrayToCommaSeperatedString(param) {
    if (!isArrayNonEmpty(param)) {
        return param;
    }
    return param.join(', ');
}
export function processApiResponseForFile(response, defaultFileName) {
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = defaultFileName;
    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    const blob = response?.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}
export function assignValueToArray(array, index, value) {
    if (array === null) {
        array = [];
    }
    while (array.length <= index) {
        array.push({});
    }
    array[index] = value;
}

export const multiplyBy10ToPower = (value, power = 2) => {
    return value * Math.pow(10, power);
};

export const getYYYYMMDDToDateString = (date) => {
    return date != undefined ? (
        (date?.toString())?.slice(6, 8) +
        '-' +
        (date?.toString())?.slice(4, 6) +
        '-' +
        (date?.toString())?.slice(0, 4)
    ) : (
        '--'
    )
};

<div><strong><span>{
                }</span></strong>
              </div>

export const getTimestampToDateInteger = (timestamp) => {
    if (timestamp)
        return moment.unix(timestamp).format("YYYYMMDD");
    else
        return null;
}

export const dateToTimestamp = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1;
    const day = dateString.substring(6, 8);
    const date = new Date(year, month, day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime() / 1000;
}

export const checkIfKeyExists = (key, obj) => key in obj;

export const getFullAddress= (address) => [
    address?.addressLine,
    address?.city,
    address?.state,
    address?.country
].filter(Boolean).join(', ');

export const getPassengerInitials = (name) => {
    return name.split(' ').map((n) => n[0]).join('').toUpperCase();
};

export const isFemalePassenger=(salutation)=>{
    return ['Miss', 'Mrs', 'Ms'].includes(salutation)  ;
}
export const getPassengerColor=(salutation)=>{
    return isFemalePassenger(salutation) ? FEMALE_COLOR : MALE_COLOR;
}
export const getValue=(value,defaultValue=0)=>(value??defaultValue)


export const customerFromCustomerId=(id)=>{
    const customer = CustomerLabelService.customers.find(customer => customer._id === id);
    return customer
}


export const truncateStringToWords = (str, numWords) => str ? str.split(' ').slice(0, numWords).join(' ') + (str.split(' ').length > numWords ? '...' : '') : '';

export  const getTimeDifference = (timestamp) => {
    const seconds = Math.floor((new Date() - timestamp * 1000) / 1000);
    const intervals = [
        { label: 'y', seconds: 31536000 },
        { label: 'm', seconds: 2592000 },
        { label: 'd', seconds: 86400 },
        { label: 'h', seconds: 3600 },
        { label: 'm', seconds: 60 },
    ];
    for (let i = 0; i < intervals.length; i++) {
        const interval = intervals[i];
        const count = Math.floor(seconds / interval.seconds);
        if (count > 0) {
            return `${count}${interval.label}`;
        }
    }
    return 'just now';
};

export const getMonthRangeFilter = (month) => {
    let today = parseInt(moment().format('YYYYMMDD'), 10);
    let previousMonth = parseInt(moment().subtract(month, 'months').startOf('month').format('YYYYMMDD'), 10);
    return [previousMonth, today];

}

export function getYesterdayDateString() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const year = yesterday.getFullYear();
    const month = String(yesterday.getMonth() + 1).padStart(2, '0');
    const day = String(yesterday.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

export const convertMinutesToHumanReadable = (totalMinutes) => {
    const totalHours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    
    const days = Math.floor(totalHours / 24);
    const remainingHours = totalHours % 24;

    let result = '';
    if (days > 0) {
        result += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (remainingHours > 0) {
        result += `${remainingHours} hr `;
    }
    if (minutes > 0) {
        result += `${minutes} min`;
    }
    return result.trim() || '0 min';
}

export const formatDateToMonthDay = (date) => {
    if (!date) return "";
    return moment(String(date), 'YYYYMMDD').format('MMMM DD');
};

export const formatTimeToHourMinute = (time) => {
    if (!time || isNaN(time)) return "";
    return moment(String(time), 'HHmm').isValid() ? moment(String(time), 'HHmm').format('HH:mm') : "";
};

export const formatDateWithConditions = (date) => {
    const now = moment();
    const inputDate = moment(date * 1000);

    if (now.isSame(inputDate, 'day')) {
        return inputDate.format('h:mm A');
    } else if (now.diff(inputDate, 'years') >= 1) {
        return inputDate.format('DD/MM/YYYY');
    } else {
        return inputDate.format('MMM DD');
    }
};

export const formatUnixTimestampToDateTime = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) return ""; 
    const date = moment.unix(timestamp);
    return date.isValid() ? `${date.format('MMMM DD')} ${date.format('HH:mm')}` : "";
};


export const downloadMedia = (url, typeText) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const link = document.createElement('a');
            link.href = url;
            link.download = typeText !== "pdf" ? `download-${typeText}` : url.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve();
        }, 3000);
    });
};

export const downloadText = (text, name) => {
    return new Promise((resolve) => {
        const blob = new Blob([text], { type: "text/plain" });
        const link = document.createElement("a");
        link.download = name ? name + ".txt" : "file.txt";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        resolve();
    });
};

export const fileTypes = {
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff'],
    audio: ['mp3', 'wav', 'ogg', 'm4a', 'flac'],
    video: ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'],
    gif: ['gif'],
    pdf: ['pdf'],
    xlsx: ['xlsx']
  };


  export async function copyToClipboard(msg) {
    try {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(msg);
            toastr.success('Copied');
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = msg;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                toastr.success('Copied');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    } catch (error) {

    }
}


export const parseDateFromInt = (dateInt) => {
    const dateStr = dateInt.toString();
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Months are 0-indexed in JS Date
    const day = parseInt(dateStr.substring(6, 8), 10);
    return new Date(year, month, day);
  };
  
 export const mapColumnsToAccessor = (columns) => {
    const columnMap = {};
    columns.forEach(({ title, accessor }) => {
      if (title !== "Action" && accessor) {
        columnMap[title] = accessor;
      }
    });
    return columnMap;
  };

  export const setFirstUIPreference = async (data, gridColumns,uiPref) =>{
    if((data!=null || data!=undefined)){
        if(data!="[]")return false;
    }
        const columnTitles = gridColumns?.map(column => column.title);
        const jsonString = JSON.stringify(columnTitles);
        await doPUT("/api/ui-preference/update", { key: uiPref, value: jsonString });
        UIPrefService?.updatePrefs(uiPref, jsonString ?? '[]');
        return true;
  }