export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/client/grid?`;
    url += `rows=${sizePerPage ?? -1}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/client/${id}/detail`,
  save: `/api/client/create`,
  delete: (id) => `/api/client/${id}/delete`,
  bulkDelete: (id) => `/api/client/delete`,
  update: `/api/client/update`,
  generateToken: `/api/agent/generate-token`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Client Name",
    filterName: "name",
    type: "text",
  }

  // {
  //   label: "Company",
  //   filterLabel: "Company",
  //   filterName: "company_id",
  //   type: "entitySelect",
  // },
  // {
  //   label: "Office",
  //   filterLabel: "Office",
  //   filterName: "office_id",
  //   type: "text",
  // },

];

export const CLIENT_AGENTS = [
  {
    label: "SMART_TRANSPORT",
    value: "SMART_TRANSPORT",
  }
];