import { CompanySelect, ClientSelect } from "../../components";


export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Client",
            accessor: "client",
            renderer: (dataObj) => {
                return <div>
                    <ClientSelect
                        asLabel
                        corpType="supplier"

                        value={dataObj?.client_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },

        {
            title: "Client Company",
            accessor: "clientCompany_id",

            renderer: (dataObj) => {
                return <div>
                    <CompanySelect
                        asLabel
                        corpType="client_id"

                        value={dataObj?.clientCompany_id}
                        onChange={(v) => { }}
                        className=""
                    />
                </div>;
            },
            show: true,
        },

        {
            title: "PO Number",
            accessor: "no",
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
