import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/PurchaseOrder/PurchaseConstant";

class PurchaseOrderSelect {
    queue = [];
    isFetching = false;
    records=[]
    constructor() {
        makeAutoObservable(this);
    }

    fetchSingle = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this?.records?.length) {
                    const response = await doGET(ENDPOINTS.grid());
                    if (response.status === 200) {
                        this.records = response?.data?.rows?.map((v) => ({
                            label: v?.no,
                            value: v?._id,
                            ...v
                        }));
                    } else {
                        // Handle error
                        this.error = response.data;
                    }
                }

                while (this.queue.length > 0) {
                    const { resolve } = this.queue.shift();
                    resolve();
                }

            } catch (err) {
                this.error = err;
                while (this.queue.length > 0) {
                    const { reject } = this.queue.shift();
                    reject(err);
                }
            } finally {
                this.isFetching = false;
            }
        });
    }

    fetchByClient = async (filterUrl)=>{
        let response = await doGET(ENDPOINTS.grid(null,null,filterUrl));
        if (response.status === 200) {
            response= response?.data?.rows?.map((v) => ({
                label: v?.no,
                value: v?._id,
                ...v
            }));
            return response;
        } else {
            // Handle error
            this.error = response.data;
        }
    }
    fetch = async function (filterUrl="") {
        if (this.isFetching) {
            return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        };
        this.isFetching = true;
        this.fetchSingle()
    };
}

const PurchaseOrderService = new PurchaseOrderSelect();
export default PurchaseOrderService;
