import { DateSelect, InputField } from "../../components";
import React, { useContext } from "react";
import { I18nContext } from "../../store/context/i18nContext";
import { downloadText, getValue } from "../../util/Util";
import ClientService from "./ClientService";
import { LuRefreshCcwDot } from "react-icons/lu";
import { FiDownload } from "react-icons/fi";
import { DialogContext } from "../../store/context/DialogContext";


export default function ClientAgentCredentials({ data, hasErr, onChange = () => { } }) {
    const { t } = useContext(I18nContext);
    const { showError, showMessage } = useContext(DialogContext);

    const generateNewToken = async () => {
        try {
            await ClientService.generateToken({ module: "client", _id: data._id });
            showMessage("Token Updated");
        } catch (e) {
            showError(e)
        }
    }

    return (
        <React.Fragment>
            {data?.isAPI && data?.clientExtn?.clientAgent === 'SMART_TRANSPORT' && <InputField
                placeholder={"Smart Transport API Key"}
                value={data?.clientExtn?.clientAgentCredentials?.smartTransport?.apiKey}
                required={true}
                label={t("API Key")}
                onChange={(v) => onChange({
                    ...getValue(data?.clientExtn?.clientAgentCredentials, {}),
                    smartTransport: {
                        apiKey: v
                    }
                })}
                error={data?.name?.length === 0 ? t("Please enter API Key") : ""}
                showErr={hasErr}
                type="text"
                className="col-sm-6"
            />}

            {data?._id && data?.isAPI && data?.clientExtn?.clientAgent &&
                <div className="mt-4 p-2 align-items-center gap-2" style={{ width: "fit-content", background: "#ebe8e8", borderRadius: "5px" }}>
                    <div className="d-flex p-2 align-items-center gap-2">
                        <div style={{ width: "95px" }}>
                            <b>{t("Zoyride Key")}</b>
                        </div>
                        <div
                            style={{ width: "fit-content" }}
                            className="d-flex ms-2 align-items-center gap-2"
                        >
                            {data?.clientExtn?.clientAgentCredentials?.zyrdKey &&
                                <>
                                    <FiDownload style={{ fontSize: 20, cursor: "pointer" }}
                                        className="text-secondary" onClick={() => {
                                            downloadText(data?.clientExtn?.clientAgentCredentials?.zyrdKey, data.name.split(" ")[0] + "_token");
                                        }} />
                                    <div style={{ cursor: "pointer" }}
                                        className="text-secondary text-decoration-underline" onClick={() => {
                                            downloadText(data?.clientExtn?.clientAgentCredentials?.zyrdKey, data.name.split(" ")[0] + "_token");
                                        }}>{t("Download Token")}</div>
                                </>
                            }
                            <br /><br /><br />
                            <i>{t('Use this token to call APIs provided by Zoyride')}</i>
                            <LuRefreshCcwDot title={t("Generate new token")}
                                style={{ fontSize: 20, cursor: "pointer" }} className="text-secondary" onClick={() => {
                                    generateNewToken();
                                }} />
                            <div style={{ cursor: "pointer" }}
                                className="text-secondary text-decoration-underline" onClick={() => {
                                    generateNewToken();
                                }}>{t("Generate New")}</div>**
                        </div>
                    </div>

                    <div className="d-flex p-2 align-items-center gap-2">
                        <div style={{ width: "95px" }}>
                            <b>{t("Key Expiry")}</b>
                        </div>
                        <div
                            style={{ width: "fit-content" }}
                            className="d-flex ms-2 align-items-center gap-2"
                        >
                            <DateSelect
                                placeholder={t("Key Expiry")}
                                value={data?.clientExtn?.clientAgentCredentials?.expiry}
                                label={""}
                                onChange={(v) => onChange({
                                    ...getValue(data?.clientExtn?.clientAgentCredentials, {}),
                                    expiry: v
                                })}
                                error={
                                    data?.clientExtn?.clientAgentCredentials?.expiry?.length === 0
                                        ? t("Please enter Expiry Date")
                                        : ""
                                }
                                showErr={hasErr}
                                type="text"
                                className=" col-sm-4 col-12"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-1 w-100">
                        <i>**{t('Note')} : {t('Please save client credentials first before generating the token !')}</i>
                    </div>
                </div>
            }

        </React.Fragment>
    )
}
