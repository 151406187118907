import React from 'react'
import { CLIENT_AGENTS } from './ClientConstant';
import { SelectField } from '../../components';

export default function ClientAgentSelect(props) {
    const agents = CLIENT_AGENTS;
    return (
        <React.Fragment>
            <SelectField
                className={props?.className}
                onChange={props?.onChange}
                data={agents}
                value={props?.value}
                label={"Client Agent"}
                error={!props?.value ? "Please Select Client Agent" : ""}
                required={props?.required}
                showErr={props?.showErr}
                inline={props?.inline}
                borderNone={props?.borderNone}
                RightComponent={props?.RightComponent}
                onClickRight={props?.onClickRight}
            />
        </React.Fragment>
    )
}
