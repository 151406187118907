import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { ClientSelect, CompanySelect, CurrencySelect, DateSelect, InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import purchaseService from "./PurchaseService";


const EditPurchase = ({ editId, onChange = () => { } }, hasErr) => {

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const fetch = async () => {
        try {
            setLoading(true);
            let taxes = await purchaseService.get(editId);
            setLoading(false)
            setData(taxes);
        } catch (error) {
            setLoading(false)
            showError(error)
        }
    };

    useEffect(() => {
        setData({})
        if (editId) fetch();
    }, [editId, purchaseService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);

    return (
        <React.Fragment>
            {loading ?
                <div className="d-flex justify-content-center align-items-center">
                    <ThreeBounce size={50} color="pink" />
                </div> :
                <Form
                    className="p-3"
                    style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                >

                    <Row className="mt-3 mb-3">
                        <Col className="col-4">
                            <ClientSelect
                                value={data?.client_id}
                                onChange={(v) => setData({ ...data, client_id: v._id })}
                            />
                        </Col>

                        <Col className="col-4">

                            <CompanySelect
                                corpType="client"
                                value={data?.clientCompany_id}
                                onChange={(v) =>
                                    setData({
                                        ...data, clientCompany_id: v?.value,
                                    })
                                }
                                corp_id={data?.client_id}
                                innerContainerClassName="input-group-post"
                                label="Client Company"
                            />
                        </Col>

                        <Col className="col-3">
                            <InputField
                                label={t("PO Number")}
                                type="text"
                                value={data?.no}
                                onChange={(v) => setData({ ...data, no: v })}
                            // required={true}
                            />
                        </Col>



                    </Row>
                    <Row className="mt-3 mb-3">
                        <DateSelect
                            placeholder={t("ValidFrom")}
                            label={t("Issue Date")}
                            value={data?.issueDate}
                            onChange={(v) => setData({ ...data, issueDate: v })}
                            className="col-sm-4"
                            type="text"
                        />

                        <DateSelect
                            placeholder={t("ValidFrom")}
                            label={t("Expiry Date")}
                            value={data?.expiryDate}
                            onChange={(v) => setData({ ...data, expiryDate: v })}
                            className="col-sm-4"
                            type="text"
                        />

                    </Row >
                    <Row className="mt-3 mb-3">
                        <Col className="col-4">
                            <CurrencySelect
                                value={data?.currency}
                                onChange={(v) => setData({ ...data, currency: v })}
                            />
                        </Col>

                        <Col className="col-4">
                            <InputField
                                label={t("Amount")}
                                type="text"
                                value={data?.amount}
                                onChange={(v) => setData({ ...data, amount: v })}
                            />
                        </Col>

                    </Row>


                    <Row className="mt-3 mb-3">
                        <div className="col-6">
                            <div className="mb-2">{t("Note")}</div>
                            <textarea className="inputfield" rows={10} style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", resize: "none" }}
                                value={data?.notes}
                                onChange={(v) => setData({
                                    ...data,
                                    notes: v.target.value
                                })}
                            >
                            </textarea>

                        </div>


                    </Row>




                </Form>
            }
        </React.Fragment>
    );
};

export default observer(EditPurchase);