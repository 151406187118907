import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ModalComponent } from "../../../components/index";
import Purchase from "../../../pages/PurchaseOrder/Purchase";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { UserContext } from "../../../store/context/UserContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import PurchaseOrderSelectService from "./PurchaseOrderSelectService";

const PurchaseOrderSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    multi,
    asLabel,
    plain,
    outline,
    wrapping,
    client_id,
    ...props
}) => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext)
    const { userContent } = useContext(UserContext);
    const { t } = useContext(I18nContext)

    const fetchPurchaseOrder = async () => {
        try {
            let filterUrl = ""
            if (client_id) {
                filterUrl += `&client_id=${client_id}`
            }
            let resposne = await PurchaseOrderSelectService.fetchByClient(filterUrl);
            setPurchaseOrders(resposne);

        } catch (error) {
            // showError("Error", error)
        }
    };

    useEffect(() => {
        fetchPurchaseOrder();
    }, [client_id]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const selectClient = (selectedValue) => {
        onChange({ value: multi ? selectedValue[0]?._id : selectedValue?._id });
        closeModal();
    };

    const selectClients = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}

            className={className}
            onChange={onChange}
            data={purchaseOrders}
            required={required}
            value={value}
            label={t(`${label || 'Purchase Order'}`)}
            error={!value ? t(`Please Select ${label || "Purchase Order"}`) : ""}
            showErr={showErr}
            plain={plain}
            outline={outline}
            inline={inline}
            borderNone={borderNone}

            {...props}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}

            // showBottomBar={true}
            className={className}
            onChange={selectClients}
            data={purchaseOrders}
            value={value ?? ""}
            error={!value ? t(`Please Select ${label || "Purchase Order"}`) : ""}
            required={required}
            plain={plain}
            outline={outline}
            label={t("Purchase Order")}
            showErr={showErr}

        />
    );

    return (
        <>
            {asLabel ? multi ?
                value?.length > 0 ?
                    (<div>
                        {wrapping ?
                            <div>
                                {value?.slice(0, wrapping).map((selectedValue, index) => (
                                    <span key={selectedValue}>
                                        {purchaseOrders.find((client) => client.value === selectedValue)?.label || ""}
                                        {index < wrapping - 1 && ", "}
                                    </span>
                                ))}
                                {value.length > wrapping &&
                                    ` + ${value.length - wrapping} `
                                }
                            </div>
                            :
                            value?.map((selectedValue, index) => (
                                <span key={selectedValue}>
                                    {purchaseOrders.find((client) => client.value === selectedValue)?.label || ""}
                                    {index < value.length - 1 && ", "}
                                </span>
                            ))
                        }
                    </div>) : <div style={{ color: "grey" }}>
                        {userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>


                : (<div>{purchaseOrders?.find((client) => client?.value == value)?.label ?? <div style={{ color: "grey" }}>{userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>}</div>) :
                <>

                    {modal && (
                        <ModalComponent
                            position={"top"}
                            size={"medium"}
                            onToggle={closeModal}
                            isOpen={modal}
                        >
                            <ModalComponent.Title>
                                {t("Purchase Order")}
                            </ModalComponent.Title>

                            <ModalComponent.Body>
                                <Purchase
                                    value={value}
                                    insidePane={true}
                                    multiMode={multi}
                                    onSelect={multi ? selectClients : selectClient}
                                />
                            </ModalComponent.Body>
                        </ModalComponent>
                    )}

                    {purchaseOrders.length > 0
                        ? multi
                            ? renderSelectFieldMultiple()
                            : renderSelectField({ disabled: false })
                        : renderSelectField({ disabled: false })}
                </>
            }
        </>
    );
};

export default observer(PurchaseOrderSelect);
