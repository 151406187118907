import React from "react";
import { Button } from "reactstrap";

export default function DownloadIconButton({
  className,
  size = 24,
  style = {},
  label,
  link,
  onClick = () => { } }) {

  return (
    <div
      style={{
        color: "primary",
        display: "inline-block",
        maxWidth: "fit-content",
        ...style,
      }}
      onClick={() => {
        onClick();
      }}
    >
      <Button
        type="button"
        className={"btn ms-2 btn-primary " + className}
        style={{ height: size * 1.25, width: size * 1.25, padding: "0px" }}
      >
        <i style={{ fontSize: 20 }} className="uil-cloud-download" />
        {label}
      </Button>
    </div>
  );
}
