import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./PurchaseConstant";
import { GridColumns } from "./PurchaseGridColumn";
import purchaseService from "./PurchaseService";
import EditPurchase from "./EditPurchase";


const Purchase = ({ insidePane, multiMode, onSelect = () => { } }) => {

    let { edit_id } = useParams();
    const location = useLocation();
    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent } = useContext(UserContext);
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(edit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/tax/create" || edit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await purchaseService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
    }, [])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.no) {
            setHasErr(true)
            showError(t("Please enter PO Number"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await purchaseService.edit(detailData, editID);
                showMessage(t("Purchase Order updated successfully."));
            } else {
                const purchaseID = await purchaseService.save(detailData);
                if (purchaseID) setEditID(purchaseID)
                showMessage(t("Purchase Order saved successfully."));
                if (!insidePane) navigate(`/purchase-order/edit/${purchaseID}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);

            try {
                await purchaseService.delete(id);
                setLoading(false);
                setShowDetailPage(false)
                showMessage(t("Purchase Order Successfully"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                await purchaseService.bulkDelete(selectedIDs);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                showError(e);
            }
        }
    };


    const renderLastCol = useCallback((purchase) => {
        return (
            <>
                {userContent?.rights?.includes(2302) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/purchase-order/edit/${purchase?._id}`);
                            setEditID(purchase?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(2303) ? <DeleteLink
                    onClick={(event) => onDelete(event, purchase?._id)}
                /> : null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(purchase);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, userContent])

    return (
        <>
            <Layout
                medium
                hideAdd={!userContent?.rights?.includes(2301)}
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/purchase-order");
                    setEditID(null);
                }}

                title={t("Purchase Order")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/purchase-order/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={purchaseService.page}
                rows={purchaseService.rows}
                total={purchaseService.total}
                fetch={purchaseService.fetch}
            >
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem>{t("Upload")}</DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> Download </DropdownItem>
                            </>
                        </DropdownMenu>
                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <>
                        <DataGrid
                            gridLoading={loading}
                            data={purchaseService.records}
                            renderLastCol={renderLastCol}
                            headers={GridColumns}
                            total={purchaseService.total}
                            uiPreference="tax.grid"
                            page={purchaseService.page}
                            rowsPerPage={purchaseService.rowsPerPage}
                            selectedIDs={selectedIDs}
                            onSelectChange={(v) => {
                                onSelect(v)
                                setSelectedIDs(v)
                            }}
                            onPaginationChange={purchaseService.onPaginationChange}
                        />
                    </>
                </Layout.Table>

                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit Purchase Order") : t("Create Purchase Order")}
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditPurchase
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>
                <Layout.DetailPageFooter>
                    {editID && userContent?.rights?.includes(2303) && detailData?.corp_id ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
                    {userContent?.rights?.includes(2302) ? <SaveButton onClick={onSave} loading={loading} /> : null}
                </Layout.DetailPageFooter>
            </Layout>
        </>
    );
};
export default observer(Purchase);
